const install = function (Vue) {
    const $ygg = Vue.config.globalProperties.$ygg;
    $ygg.api = {
        login: (params = {}) => $ygg.post('index/login', params),
        register: (params = {}) => $ygg.post('index/register', params),
        sendCode: (params = {}) => $ygg.post('index/sendSms', params),
        homeInit: (params = {}) => $ygg.post('index/index', params),
        about: (params = {}) => $ygg.post('index/concerning', params),
        homeVideoList: (params = {}) => $ygg.post('video.index/index', params),
        getClassify: (params = {}) => $ygg.post('video.index/getClassify', params),
        getNext: (params = {}) => $ygg.post('video.index/getNext', params),
        SeeThevideo: (params = {}) => $ygg.post('video.index/see', params),
        subscribe: (params = {}) => $ygg.post('video.index/subscribe', params),
        history: (params = {}) => $ygg.post('video.index/history', params),
        subscription: (params = {}) => $ygg.post('video.index/saveChase', params),
        changePassword: (params = {}) => $ygg.post('index/changePassword', params),
        recordSchedule: (params = {}) => $ygg.post('video.index/recordSchedule', params),
        getLibrarySearch: (params = {}) => $ygg.post('school.index/index_data', params),
        scoolList: (params = {}) => $ygg.post('school.index/get_scool_list', params),
        majorList: (params = {}) => $ygg.post('school.index/get_major_list', params),
        searchScool: (params = {}) => $ygg.post('school.index/index_sch', params),
        searchScoolInfo: (params = {}) => $ygg.post('school.index/detail', params),
        adjustinfo: (params = {}) => $ygg.post('school.adjustinfo/index_data', params),
        adjustseedata: (params = {}) => $ygg.post('school.adjustinfo/seedata', params),
        adjustSubscribe: (params = {}) => $ygg.post('school.adjustinfo/chartcol', params),
        adjustSubscribeList: (params = {}) => $ygg.post('school.adjustinfo/collist', params),
        adjustSubscribeListInfo: (params = {}) => $ygg.post('school.adjustinfo/getcollist', params),
        easysch: (params = {}) => $ygg.post('school.index/easysch', params),
        uselog: (params = {}) => $ygg.post('school.index/uselog', params),
        collegeLibraryReport: (params = {}) => $ygg.post('school.repeat/st_see_repot', params),
        collegeLibraryReportInfo: (params = {}) => $ygg.post('school.repeat/getschfx', params),
        adjustinfoSearlist: (params = {}) => $ygg.post('school.adjustinfo/searlist', params),
        adjustinfoGetmajlist: (params = {}) => $ygg.post('school.adjustinfo/getmajlist', params),
        adjustinfoGetschool: (params = {}) => $ygg.post('school.adjustinfo/getschool', params),
        homeData: (params = {}) => $ygg.post('indexdata/index', params),
        homeContetn: (params = {}) => $ygg.post('indexdata/con', params),
        teacherList: (params = {}) => $ygg.post('index/teacherList', params),


        selfServiceHome: (params = {}) => $ygg.post('Anshelp/index', params),
        selfServiceList: (params = {}) => $ygg.post('Anshelp/bookList', params),
        selfServiceFavoriteList: (params = {}) => $ygg.post('Anshelp/bookCollection', params),
        selfServiceInfo: (params = {}) => $ygg.post('Anshelp/bookInfo', params),
        selfServiceFavorite: (params = {}) => $ygg.post('Anshelp/setCollection', params),
        // selfServiceFavorite: (params = {}) => $ygg.post('Anshelp/setCollection', params),
        selfServiceSeeVideo: (params = {}) => $ygg.post('Anshelp/see', params),
        selfServiceVideoLog: (params = {}) => $ygg.post('Anshelp/saveBrowseNum', params),


        lineCustomerList: (params = {}) => $ygg.post('school.ask/index', params),

        Rganshelp: (params = {}) => $ygg.post('/app/v1.Rganshelp/index', params),
        getPostgraduateNew: (params = {}) => $ygg.post('/app/v1.Rganshelp/getPostgraduateNew', params),
        getReply: (params = {}) => $ygg.post('/app/v1.Rganshelp/getReply', params),
        rganshelpSubmit: (params = {}) => $ygg.post('/app/v1.Rganshelp/submit', params),
        pushQuestionzw: (params = {}) => $ygg.post('/app/v1.Rganshelp/setQuestionzw', params),
        submitEvaluate: (params = {}) => $ygg.post('/app/v1.Rganshelp/submitEvaluate', params),
        black: (params = {}) => $ygg.post('/app/v1.Rganshelp/black', params),
        solveQuestion: (params = {}) => $ygg.post('/app/v1.Rganshelp/solveQuestion', params),
        setPl: (params = {}) => $ygg.post('/app/v1.Rganshelp/setPl', params),
        delReject: (params = {}) => $ygg.post('/app/v1.Rganshelp/delReject', params),

        getRealData: (params = {}) => $ygg.post('/app/v4.item/gettypes', params),
        getRealDataList: (params = {}) => $ygg.post('/app/v4.item/getpub', params),
        getRealDataDetail: (params = {}) => $ygg.post('/app/v4.item/see', params),

        // https://app.ydwechat.com/apiindex.php//v1.Rganshelp/index
        // https://app.ydwechat.com/websiteindex.php
    }
}
export default {
    install
}